import { useEffect, useState,useRef } from 'react';
import { Input, message, Pagination, Tabs } from 'antd';
import Axios from 'axios';
import { hostUrl } from '../../lib/base/base';

import './index.css'


const Preferential = () => {
  // 展示数据
  const [adPreferential, setAdPreferential] = useState([{
    advert: '',
    title: '',
    la: '',
    address: '',
    context: '',
    original: '',
    discount: '',
    price: '',
    img: '',
  }]);
  // 显示翻页总数
  const [total, setTotal] = useState<number>(0);
  const [hint, setHint] = useState(false);
  const { Search } = Input;
  const selectTabs = useRef(0);
    // 导航
    const tabs = [
      {
        key: '0',
        label: '全部',
      },
      {
        key: '1',
        label: '零食',
      },
      {
        key: '2',
        label: '服饰',
      },
      {
        key: '3',
        label: '化妆品',
      },
      {
        key: '4',
        label: '电子产品',
      },
      {
        key: '5',
        label: '生活品',
      },
    ];
  useEffect(() => {
    console.log(selectTabs.current)
  });
  // 搜索接口
  const onSearch = async (e: any) => {
    if (!e) {
     return message.error('请输入产品名称')
    }
    const reqData = {
      title: e
    }
    try {
      const res = await Axios({
        method: 'post',
        url: `${hostUrl}/api/searchPreferential`,
        params: reqData
      });
      if (res.data.data.dataList) {
        const resData = res.data.data.dataList;
        setAdPreferential(resData)
      }
      setHint(true)
    } catch (error) {
      message.error('查询出差');
    }

  }
  // 翻页
  const onChangeValue = async (e: number) => {
    const tabs = selectTabs.current;
    try {
      if (tabs === 0) {
        const reqData = {
          currentPage: e
        }
        const res = await Axios({
          method: 'post',
          url: `${hostUrl}/api/preferentialPage`,
          params: reqData
        });
        if (res.data.data.dataList) {
          // 页面显示数据
          const resData = res.data.data.dataList.rows;
          // 分页数据
          const total = res.data.data.dataList.count;
          setAdPreferential(resData);
          setTotal(total);
        }
      } else {
        const reqData = {
          currentPage: e,
          productType: tabs,
        }
        const res = await Axios({
          method: 'post',
          url: `${hostUrl}/api/preferentialType`,
          params: reqData
        });
        if (res.data.data.dataList) {
          // 页面显示数据
          const resData = res.data.data.dataList.rows;
          // 分页数据
          const total = res.data.data.dataList.count;
          setAdPreferential(resData);
          setTotal(total);
        }
      }
    } catch (error) {
      message.error('查询出差');
    }
  }
  // 切换导航
  const onChangeTabs = (e: string) => {
    const tabs = Number(e);
    selectTabs.current = tabs;
    setTotal(0);
    onChangeValue(1);
  }
  useEffect(() => {
    onChangeValue(1);
  }, []);
  return <>
    <div className='ad-perfer'>
      <div className='search-input'>
        <Search
          placeholder="搜索商品名称"
          allowClear
          enterButton="商品查找"
          size="large"
          onSearch={onSearch}
        />
      </div>
      <div className='tabs'>
        <Tabs defaultActiveKey="0" items={tabs} onChange={onChangeTabs} />
      </div>
      <div className='preferential'>
        {
          adPreferential.map((item, index) => {
            return <a href={item.address} target='_blank' rel="noreferrer" style={{ maxWidth: '26.5em' }}>
              <div className='ad-preferential-fix' key={index}>
                <div className='ad-preferential-img'>
                  <img className='ad-img' src={item.img} alt='图片' />
                  <p className='font'>{item.la}</p>
                </div>
                <div className='ad-preferential-context'>
                  <h4 className={item.advert}>{item.title}</h4>
                  <p>{item.context}</p>
                  <p className='p-originalPrice'>原价：{item.original}元</p>
                  <p className='p-discount'>优惠：{(Number(item.original) - Number(item.price)).toFixed(2)}元</p>
                  <p className='p-price'>现价：{item.price}元</p>
                </div>
              </div>
            </a>
          })
        }
      </div>
    </div>
    {hint === true ? <div className='hint'>获取最新优惠，请刷新一下网页...</div> : <Pagination style={{marginTop: '10px'}} defaultCurrent={1} total={total} showSizeChanger={false} pageSize={50} align="center" onChange={(e) => {
      onChangeValue(e)
    }} />}
  </>
};


export default Preferential;