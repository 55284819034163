import { Input } from 'antd';
import { ChangeEvent, useState, useContext, useEffect } from 'react';
import { createFun } from '../../component/useContext';



const { TextArea } = Input;
export const EditGift = () => {
    const { setEditValue, editValue } = useContext(createFun);
    const [value, setValue] = useState<string>(editValue);
    const onChangeValue = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const values = e.target.value.trim();
        setValue(values);
        setEditValue(values);
    }
    useEffect(() => {
        setEditValue(value);
    }, [value]);
    return (<div>
        <TextArea
            defaultValue={value}
            placeholder="输入文字抓阄，用“，”隔开"
            onChange={(e) => onChangeValue(e)}
            autoSize={{ minRows: 5, maxRows: 10 }}
        />
    </div>);
};
