import { useEffect, useState } from 'react';
import { createEat } from '../useContext/index';
import { LuckyWheel } from '../../lib/lucky-wheel/lucky-wheel';
import { hostUrl } from '../../lib/base/base';
import Axios from 'axios';


export default function Eat() {
    const [province, setProvince] = useState('北京');
    const [resEat, setResEat] = useState([{ id: 0, base: '', value: '' }]);
    const [luckyWheelData, setLuckyWheelData] = useState([{ id: 0, luckyName: '', probability: 0.2 }]);
    const luckyValue = () => {
        const wheelObj = resEat.find(item => item.base === province);
        if (wheelObj) {
            const editArray = wheelObj?.value.split('，');
            // 数据处理
            const luckyData = [];
            for (let i = 0; i < editArray.length; i++) {
                if (editArray[i] !== '') {
                    luckyData.push(
                        { id: i, luckyName: editArray[i], probability: 0.2 },
                    )
                }
            }
            setLuckyWheelData(luckyData);
        }
    }
    const baseEat = async () => {
        const res = await Axios.get(`${hostUrl}/api/foodsList`, {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });
        if (res.data.data.dataList){
            setResEat(res.data.data.dataList)
        }
    }
    useEffect(() => {
        luckyValue()
    }, [resEat, province]);
    useEffect(() => {
        baseEat();
    }, [])
    return <createEat.Provider value={setProvince}> <LuckyWheel items={luckyWheelData} tableLink={'吃啥'} /></createEat.Provider>
}