import { useEffect, useState } from 'react';
import { createPlay } from '../useContext/index';
import { LuckyWheel } from '../../lib/lucky-wheel/lucky-wheel';
import { hostUrl } from '../../lib/base/base';
import Axios from 'axios';

const Play = () => {
  const [province, setProvince] = useState('北京');
  const [resPlay, setResPlay] = useState([{id:0,base:'',value:''}]);
  const [luckyWheelData, setLuckyWheelData] = useState([{ id: 0, luckyName: '', probability: 0.2 }]);
  const luckyValue = () => {
    const wheelObj = resPlay.find(item => item.base === province);
    if (wheelObj) {
      const editArray = wheelObj?.value.split('，');
      // 数据处理
      const luckyData = [];
      for (let i = 0; i < editArray.length; i++) {
        if (editArray[i] !== '') {
          luckyData.push(
            { id: i, luckyName: editArray[i], probability: 0.2 },
          )
        }
      }
      setLuckyWheelData(luckyData);
    }
  }

  const basePlay = async () => {
    const res = await Axios.get(`${hostUrl}/api/palyList`,{
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
      }
    })
    if (res.data.data.dataList) {
      setResPlay(res.data.data.dataList)
    }
  }
  useEffect(() => {
    luckyValue()
  }, [resPlay,province]);
  useEffect(() => {
    basePlay()
   }, [])
  return <createPlay.Provider value={setProvince}> <LuckyWheel items={luckyWheelData} tableLink={'去玩'} /></createPlay.Provider>
};

export default Play;